import React from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import Button from '../Button';
import { FormInfo } from '../Form';
import Modal from '../Modal';
import schema from '../../json/schema/schema.json';

interface SchemaViewModalProps extends ClassNameProps {
  connection: string;
  open: boolean;
  handleClose: (e) => void;
}

const SchemaViewModal: React.FC<SchemaViewModalProps> = styled(({ connection, open, handleClose, className }) => {
  const listing = appendChild(schema, connection);

  return (
    (open && (
      <Modal
        onClick={(e) => e.stopPropagation()}
        buttons={
          <>
            <Button onClick={handleClose} variant={'white'}>
              Close
            </Button>
          </>
        }
        className={className}
        centered={true}
        title={'Schema View'}
        handleClose={handleClose}
        open={open}
      >
        <FormInfo>{listing}</FormInfo>
      </Modal>
    )) ||
    null
  );
})`
  .actions {
    .btn {
      display: block;
      margin: 0;
    }
  }
`;

const appendChild = (schema: any, element: string) => {
  if (!schema[element]) {
    return (
      <ul key={element}>
        <li>{element}</li>
      </ul>
    );
  }

  const children = schema[element].map((entry) => {
    return appendChild(schema, entry);
  });

  return (
    <ul key={element}>
      <li>{element}</li>
      <li>{children}</li>
    </ul>
  );
};

export default SchemaViewModal;
