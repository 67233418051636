import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import schema from '../../json/schema/schema.json';
import { useFeatureFlag } from '../../utils/features';
import Icon from '../Icon';
import RequiresRoles from '../Security/RequiresRoles';
import SchemaViewModal from './SchemaViewModal';

interface SchemaViewProps extends ClassNameProps {
  connection?: string;
}

const SchemaView: React.FC<SchemaViewProps> = styled(({ className, connection }) => {
  const location = useLocation();
  const path = getPathFromLocation(location);

  connection = connection || path;

  const schemasActive = useFeatureFlag('schemas');

  const [open, setOpen] = useState<boolean>(false);
  return !(schemasActive && schema[connection]) ? null : (
    <RequiresRoles roles={['admin-role']}>
      <div className={`schema-view ${className}`}>
        <span
          className="btn btn-white"
          onClick={(e: any) => {
            setOpen(true);
            e.stopPropagation();
          }}
        >
          <Icon size="lg" icon={faDatabase} />
        </span>
        <SchemaViewModal
          connection={connection}
          open={open}
          handleClose={(e) => {
            setOpen(false);
          }}
        />
      </div>
    </RequiresRoles>
  );
})`
  display: inline-flex;
  .btn {
    font-size: 12px;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
`;

const getPathFromLocation = (location: any) => {
  const parts = location.pathname.split('/');

  if (parts.length === 4 && parts[2] === 'geographies') {
    return parts[2] + '/' + parts[3];
  }

  return parts.length === 3 ? parts[2] : parts.length > 3 ? parts[2] + '/' : '';
};

export default SchemaView;
