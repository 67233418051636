declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  NODE_ENV: string;
  REACT_APP_GQL_URL: string;
  REACT_APP_GQL_V2_URL: string;
  REACT_APP_GQL_ION_URL: string;
  REACT_APP_GRAPHIQL_URL: string;
  REACT_APP_GRAPHIQL_V2_URL: string;
  REACT_APP_GRAPHIQL_ION_URL: string;
  REACT_APP_CLEAR_CACHE_URL: string;
  REACT_APP_XHR_ROOT_URL: string;
  REACT_APP_NODE_ENV: string;
  REACT_APP_AUTH_URL: string;
  REACT_APP_AUTH_REALM: string;
  REACT_APP_AUTH_CLIENT_SECRET: string;
  REACT_APP_GOOGLE_API_KEY: string;
  REACT_APP_GOOGLE_ANALYTICS_KEY: string;
  REACT_APP_LIMPAR_BASE_URL: string;
  REACT_APP_CENSUS_API_KEY: string;
  REACT_APP_HOSTNAME: string;
  REACT_APP_BASE_SUBSCRIPTION_PRICE_CODE: string;
  REACT_APP_OPN_SESAME_BASE_URL: string;
  REACT_APP_MERCURY_BASE_URL: string;
  REACT_APP_MERCURY_FE_BASE_URL: string;
  REACT_APP_RUMBLEUP_BASE_URL: string;
  REACT_APP_DATA_LOAD_URL: string;
  REACT_APP_MATCHING_ENV: string;
  REACT_APP_HIGHER_ED_URL: string;
};
export const env: EnvType = { ...process.env, ...window.env };
