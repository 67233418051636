import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import debounce from 'lodash/debounce';
import cacheConfig from './cacheConfig';
import { env } from './env';
import { getJWTToken } from './utils/auth';
import { showError } from './utils/message';

const link = new BatchHttpLink({
  uri: env.REACT_APP_GQL_URL || 'http://localhost:3001/graphql',
  batchMax: 3,
  batchInterval: 10
});

const linkV2 = new BatchHttpLink({
  uri: env.REACT_APP_GQL_V2_URL || 'http://localhost:3001/v2/graphql',
  batchMax: 3,
  batchInterval: 10
});

const linkIon = new BatchHttpLink({
  uri: env.REACT_APP_GQL_ION_URL || 'http://localhost:3001/ion/graphql',
  batchMax: 3,
  batchInterval: 10
});

const linkDataLoad = new BatchHttpLink({
  uri: env.REACT_APP_DATA_LOAD_URL || 'http://localhost:3001/kakaruk/graphql',
  batchMax: 3,
  batchInterval: 10
});

const linkHigherEd = new BatchHttpLink({
  uri: env.REACT_APP_HIGHER_ED_URL || 'http://localhost:3001/higher-ed/graphql',
  batchMax: 3,
  batchInterval: 10
});

const showNetworkNotification = debounce(
  () => showError('Unable to connect to server. Please check your network connection.'),
  200
);
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    showNetworkNotification();
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from wherever you store it
  const token = getJWTToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // Only pass the authorization header if we have a JWT
      ...(token && token !== 'null' ? { authorization: `Bearer ${token}` } : null)
    }
  };
});

export const client = new ApolloClient({
  connectToDevTools: env.REACT_APP_NODE_ENV === 'development',
  link: ApolloLink.split(
    (operation) => operation.getContext().version === '2',
    authLink.concat(errorLink).concat(linkV2),
    ApolloLink.split(
      (operation) => operation.getContext().version === 'ion',
      authLink.concat(errorLink).concat(linkIon),
      ApolloLink.split(
        (operation) => operation.getContext().version === 'dataload',
        authLink.concat(errorLink).concat(linkDataLoad),
        ApolloLink.split(
          (operation) => operation.getContext().version === 'higher-ed',
          authLink.concat(errorLink).concat(linkHigherEd),
          authLink.concat(errorLink).concat(link)
        )
      )
    )
  ),
  cache: new InMemoryCache(cacheConfig)
});

export const purgeCachedEntities = (entites: string[]) => {
  return (cache: InMemoryCache) => {
    entites.forEach((e: string) => {
      cache.evict({ fieldName: e });
    });
  };
};
