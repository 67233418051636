import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError
} from 'react-router-dom';
import { styled } from 'styled-components';
import { VersionState } from '../../App';

import {
  ADMIN_ROLE,
  DATA_MANAGER_ROLE,
  FEATURE_ROLE,
  JUDICIAL_ROLE,
  HIGHER_ED_ROLE,
  MESSAGING_ROLE,
  TEAM_MANAGER,
  VOTER_ROLE
} from '../../utils/auth';
import ErrorHandler from '../ErrorHandler';
import Loading from '../Loading';
import Authorize from '../Security/Authorize';
import Secured from '../Security/Secured';
import { BaseLayout } from '../layouts/BaseLayout';
import DataLoadNavigationConfig from '../layouts/DataLoadNavigationConfig';
import { LeftNav } from '../layouts/LeftNav';
import { MainLayout } from '../layouts/MainLayout';
import MessagingNavigationConfig from '../layouts/MessagingNavigationConfig';
import { routes } from './routes';
const Features = React.lazy(() => import('../../pages/Features'));
const Teams = React.lazy(() => import('../../pages/TeamsListing'));
const Team = React.lazy(() => import('../../pages/Team'));
const EmailOutreachListing = React.lazy(() => import('../../pages/EmailOutreachListing'));
const EmailOutreach = React.lazy(() => import('../../pages/EmailOutreach'));
const NursingHomesListing = React.lazy(() => import('../../pages/NursingHomesListing'));
const NursingHome = React.lazy(() => import('../../pages/NursingHome'));
const SubsidizedHousingListing = React.lazy(() => import('../../pages/SubsidizedHousingListing'));
const SubsidizedHousing = React.lazy(() => import('../../pages/SubsidizedHousing'));
const CampaignContributionsListing = React.lazy(() => import('../../pages/CampaignContributionsListing'));
const CampaignContribution = React.lazy(() => import('../../pages/CampaignContribution'));
const Property = React.lazy(() => import('../../pages/Property'));
const StoryListing = React.lazy(() => import('../../pages/Messaging/StoryListing'));
const AutomatedDataLoadList = React.lazy(() => import('../../pages/DataLoad/Automated'));
const AutomatedDataLoadStart = React.lazy(() => import('../../pages/DataLoad/Automated/Start'));
const Dashboard = React.lazy(() => import('../../pages/Dashboard'));
const CountyList = React.lazy(() => import('../../pages/ElectionResultsListing/CountyList'));
const PrecinctTownshipList = React.lazy(() => import('../../pages/ElectionResultsListing/PrecinctTownshipList'));
const TownshipVoterList = React.lazy(() => import('../../pages/ElectionResultsListing/TownshipVoterList'));
const Geographies = React.lazy(() => import('../../pages/Geographies'));
const MobileHome = React.lazy(() => import('../../pages/MobileHome'));
const MobileHomeListing = React.lazy(() => import('../../pages/MobileHomeListing'));
const NcoaListing = React.lazy(() => import('../../pages/NcoaListing'));
const Pension = React.lazy(() => import('../../pages/Pension'));
const PensionsListing = React.lazy(() => import('../../pages/PensionsListing'));
const SchoolBoardListing = React.lazy(() => import('../../pages/SchoolBoardListing'));
const SchoolBoardVoterList = React.lazy(() => import('../../pages/SchoolBoardListing/SchoolBoardVoterList'));
const Union = React.lazy(() => import('../../pages/Union'));
const UnionsListing = React.lazy(() => import('../../pages/UnionsListing'));
const UnregisteredListing = React.lazy(() => import('../../pages/UnregisteredListing'));

const Dormitory = React.lazy(() => import('../../pages/Dormitory'));
const DormitoryListing = React.lazy(() => import('../../pages/DormitoryListing'));
const MessagingCampaign = React.lazy(() => import('../../pages/Messaging/Campaign'));
const UserApproval = React.lazy(() => import('../../pages/UserApproval'));
const States = React.lazy(() => import('../../pages/Geographies/States'));
const StateDetail = React.lazy(() => import('../../pages/Geographies/States/StateDetail'));
const ZipCodes = React.lazy(() => import('../../pages/Geographies/ZipCodes'));
const ZipCodeDetail = React.lazy(() => import('../../pages/Geographies/ZipCodes/ZipCodeDetail'));
const Counties = React.lazy(() => import('../../pages/Geographies/Counties'));
const CountyDetail = React.lazy(() => import('../../pages/Geographies/Counties/CountyDetail'));
const Cities = React.lazy(() => import('../../pages/Geographies/Cities'));
const GeographyDashboard = React.lazy(() => import('../../pages/Geographies/GeographyDashboard'));
const CityDetail = React.lazy(() => import('../../pages/Geographies/Cities/CityDetail'));
const Townships = React.lazy(() => import('../../pages/Geographies/Townships'));
const TownshipDetail = React.lazy(() => import('../../pages/Geographies/Townships/TownshipDetail'));
const Wards = React.lazy(() => import('../../pages/Geographies/Wards'));
const WardDetail = React.lazy(() => import('../../pages/Geographies/Wards/WardDetail'));
const Neighborhoods = React.lazy(() => import('../../pages/Geographies/Neighborhoods'));
const NeighborhoodDetail = React.lazy(() => import('../../pages/Geographies/Neighborhoods/NeighborhoodDetail'));
const Precincts = React.lazy(() => import('../../pages/Geographies/Precincts'));
const PrecinctDetail = React.lazy(() => import('../../pages/Geographies/Precincts/PrecinctDetail'));
const UsHouseDistricts = React.lazy(() => import('../../pages/Geographies/UsHouseDistricts'));
const UsHouseDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/UsHouseDistricts/UsHouseDistrictDetail')
);
const StateHouseDistricts = React.lazy(() => import('../../pages/Geographies/StateHouseDistricts'));
const StateHouseDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/StateHouseDistricts/StateHouseDistrictDetail')
);
const CensusTracts = React.lazy(() => import('../../pages/Geographies/CensusTracts'));
const CensusTractDetail = React.lazy(() => import('../../pages/Geographies/CensusTracts/CensusTractDetail'));
const JudicialDistricts = React.lazy(() => import('../../pages/Geographies/JudicialDistricts'));
const JudicialDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/JudicialDistricts/JudicialDistrictDetail')
);
const StateSenateDistricts = React.lazy(() => import('../../pages/Geographies/StateSenateDistricts'));
const StateSenateDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/StateSenateDistricts/StateSenateDistrictDetail')
);
const HighSchoolDistricts = React.lazy(() => import('../../pages/Geographies/HighSchoolDistricts'));
const HighSchoolDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/HighSchoolDistricts/HighSchoolDistrictDetail')
);
const BlockGroups = React.lazy(() => import('../../pages/Geographies/BlockGroups'));
const BlockGroupDetail = React.lazy(() => import('../../pages/Geographies/BlockGroups/BlockGroupDetail'));
const ElementarySchoolDistricts = React.lazy(() => import('../../pages/Geographies/ElementarySchoolDistricts'));
const MiddleSchoolDistricts = React.lazy(() => import('../../pages/Geographies/MiddleSchoolDistricts'));
const ElementarySchoolDistrictDetail = React.lazy(
  () => import('../../pages/Geographies/ElementarySchoolDistricts/ElementarySchoolDistrictDetail')
);
const MiddleSchoolDistrictsDetail = React.lazy(
  () => import('../../pages/Geographies/MiddleSchoolDistricts/MiddleSchoolDistrictDetail')
);
const ParkDistricts = React.lazy(() => import('../../pages/Geographies/ParkDistricts'));
const ParkDistrictDetail = React.lazy(() => import('../../pages/Geographies/ParkDistricts/ParkDistrictDetail'));
const CensusBlocks = React.lazy(() => import('../../pages/Geographies/CensusBlocks'));
const CensusBlockDetail = React.lazy(() => import('../../pages/Geographies/CensusBlocks/CensusBlockDetail'));
const SubDivisions = React.lazy(() => import('../../pages/Geographies/SubDivisions'));
const SubDivisionDetail = React.lazy(() => import('../../pages/Geographies/SubDivisions/SubDivisionDetail'));

const Candidate = React.lazy(() => import('../../pages/Candidate'));
const CandidatesListing = React.lazy(() => import('../../pages/CandidatesListing'));
const Case = React.lazy(() => import('../../pages/Case'));
const CasesListing = React.lazy(() => import('../../pages/CasesListing'));
const Court = React.lazy(() => import('../../pages/Court'));
const CourtsListing = React.lazy(() => import('../../pages/CourtsListing'));
const Judge = React.lazy(() => import('../../pages/Judge'));
const JudgesListing = React.lazy(() => import('../../pages/JudgesListing'));
const Lawfirm = React.lazy(() => import('../../pages/Lawfirm'));
const LawfirmsListing = React.lazy(() => import('../../pages/LawfirmsListing'));
const Lawschool = React.lazy(() => import('../../pages/Lawschool'));
const Lawyer = React.lazy(() => import('../../pages/Lawyer'));
const LawyersListing = React.lazy(() => import('../../pages/LawyersListing'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const PeopleListing = React.lazy(() => import('../../pages/PeopleListing'));
const Person = React.lazy(() => import('../../pages/Person'));
const PPPLoan = React.lazy(() => import('../../pages/PPPLoan'));
const PPPLoansListing = React.lazy(() => import('../../pages/PPPListing'));
const PropertyListing = React.lazy(() => import('../../pages/PropertyListing'));
const SchoolsListing = React.lazy(() => import('../../pages/SchoolsListing'));
const University = React.lazy(() => import('../../pages/University'));
const RequiresActivation = React.lazy(() => import('../../pages/RequiresActivation'));
const HigherEducationSalariesListing = React.lazy(() => import('../../pages/HigherEducationSalariesListing'));
const HigherEducationSalary = React.lazy(() => import('../../pages/HigherEducationSalary'));
const HigherEducationSchoolListing = React.lazy(() => import('../../pages/HigherEd/SchoolListing'));
const HigherEducationSchool = React.lazy(() => import('../../pages/HigherEd/School'));
const HigherEducationStudentListing = React.lazy(() => import('../../pages/HigherEd/StudentListing'));
const HigherEducationEmploymentListing = React.lazy(() => import('../../pages/HigherEd/EmploymentListing'));
const ElectionResultsList = React.lazy(() => import('../../pages/ElectionResultsListing'));
const OrganizationsListing = React.lazy(() => import('../../pages/OrganizationsListing'));
const Organization = React.lazy(() => import('../../pages/Organization'));
const VoterFilesListing = React.lazy(() => import('../../pages/VoterFilesListing'));
const PortfolioListing = React.lazy(() => import('../../pages/Messaging/PortfolioListing'));
const MessagingPortfolio = React.lazy(() => import('../../pages/Messaging/Portfolio'));
const MessagingCampaignListing = React.lazy(() => import('../../pages/Messaging/MessagingCampaignListing'));
const CampaignStatistics = React.lazy(() => import('../../pages/Messaging/CampaignStatistics'));
const GroupListing = React.lazy(() => import('../../pages/Messaging/GroupListing'));
const Group = React.lazy(() => import('../../pages/Messaging/Group'));
const Arrest = React.lazy(() => import('../../pages/Arrest'));
const DataLoadStart = React.lazy(() => import('../../pages/DataLoad/Start'));
const DataLoadList = React.lazy(() => import('../../pages/DataLoad/List'));

type RouteMatcherProps = {
  versionState: VersionState;
};

const RouteError = styled(({ className }) => {
  const error = useRouteError() as any;

  return <ErrorHandler error={error} resetErrorBoundary={() => window.location.reload()} />;
})``;

const router = (versionState: VersionState) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<BaseLayout />} errorElement={<RouteError />}>
        <Route index element={<Navigate replace to={`/${routes.app}`} />} />

        <Route
          path={routes.automatedDataLoad}
          element={
            <Secured>
              <MainLayout
                leftNav={<LeftNav navigationConfig={DataLoadNavigationConfig} />}
                versionState={versionState}
              />
            </Secured>
          }
        >
          <Route
            index
            path={''}
            element={
              <Authorize roles={[DATA_MANAGER_ROLE]}>
                <AutomatedDataLoadList />
              </Authorize>
            }
          />
          <Route
            path={routes.automatedDataLoadStart}
            element={
              <Authorize roles={[DATA_MANAGER_ROLE]}>
                <AutomatedDataLoadStart />
              </Authorize>
            }
          />
        </Route>

        <Route
          path={routes.dataLoad}
          element={
            <Secured>
              <MainLayout
                leftNav={<LeftNav navigationConfig={DataLoadNavigationConfig} />}
                versionState={versionState}
              />
            </Secured>
          }
        >
          <Route
            index
            path={''}
            element={
              <Authorize roles={[DATA_MANAGER_ROLE]}>
                <DataLoadList />
              </Authorize>
            }
          />

          <Route
            path={routes.dataLoadStart}
            element={
              <Authorize roles={[DATA_MANAGER_ROLE]}>
                <DataLoadStart />
              </Authorize>
            }
          />
        </Route>

        <Route
          path={routes.messaging}
          element={
            <Secured>
              <MainLayout
                leftNav={<LeftNav navigationConfig={MessagingNavigationConfig} />}
                versionState={versionState}
              />
            </Secured>
          }
        >
          <Route index element={<Navigate to={routes.messagingGroups} />} />

          <Route
            path={routes.messagingCampaignStats}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <CampaignStatistics />
              </Authorize>
            }
          />
          <Route
            index
            path={routes.messagingPortfolios}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <PortfolioListing />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingNewPortfolio}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <MessagingPortfolio />
              </Authorize>
            }
          />

          <Route
            path={routes.messagingEditPortfolio}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <MessagingPortfolio />
              </Authorize>
            }
          />
          <Route
            index
            path={routes.messagingCampaigns}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <MessagingCampaignListing />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingGroups}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <GroupListing />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingNewGroup}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <Group />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingEditGroup}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <Group />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingNewCampaign}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <MessagingCampaign />
              </Authorize>
            }
          />
          <Route
            path={routes.messagingEditCampaign}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <MessagingCampaign />
              </Authorize>
            }
          />

          <Route
            path={routes.messagingStories}
            element={
              <Authorize roles={[MESSAGING_ROLE]}>
                <StoryListing />
              </Authorize>
            }
          />
        </Route>

        <Route
          path={routes.app}
          element={
            <Secured>
              <MainLayout leftNav={<LeftNav />} versionState={versionState} />
            </Secured>
          }
        >
          <Route
            index
            element={
              <Authorize roles={[JUDICIAL_ROLE, VOTER_ROLE, HIGHER_ED_ROLE]}>
                <Dashboard />
              </Authorize>
            }
          />

          <Route
            path={routes.people}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PeopleListing />
              </Authorize>
            }
          />
          <Route
            path={routes.person}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Person />
              </Authorize>
            }
          />
          <Route
            path={routes.personFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Person />
              </Authorize>
            }
          />
          <Route
            path={routes.properties}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PropertyListing />
              </Authorize>
            }
          />
          <Route
            path={routes.property}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Property />
              </Authorize>
            }
          />
          <Route
            path={routes.propertyFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Property />
              </Authorize>
            }
          />
          <Route
            path={routes.campaignContributions}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <CampaignContributionsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.campaignContribution}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <CampaignContribution />
              </Authorize>
            }
          />
          <Route
            path={routes.campaignContributionFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <CampaignContribution />
              </Authorize>
            }
          />
          <Route
            path={routes.pppLoans}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PPPLoansListing />
              </Authorize>
            }
          />
          <Route
            path={routes.pppLoan}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PPPLoan />
              </Authorize>
            }
          />
          <Route
            path={routes.pppLoanFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PPPLoan />
              </Authorize>
            }
          />
          <Route
            path={routes.candidates}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <CandidatesListing />
              </Authorize>
            }
          />
          <Route
            path={routes.candidate}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Candidate />
              </Authorize>
            }
          />
          <Route
            path={routes.candidateFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Candidate />
              </Authorize>
            }
          />
          <Route
            path={routes.electionResults}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <ElectionResultsList />
              </Authorize>
            }
          />
          <Route
            path={routes.electionCountyList}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <CountyList />
              </Authorize>
            }
          />
          <Route
            path={routes.electionTownshipPrecinctList}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PrecinctTownshipList />
              </Authorize>
            }
          />

          <Route
            path={routes.electionTownshipVoterList}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <TownshipVoterList />
              </Authorize>
            }
          />

          <Route
            path={routes.courts}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <CourtsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.court}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Court />
              </Authorize>
            }
          />
          <Route
            path={routes.courtFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Court />
              </Authorize>
            }
          />
          <Route
            path={routes.judges}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <JudgesListing />
              </Authorize>
            }
          />
          <Route
            path={routes.judge}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Judge />
              </Authorize>
            }
          />
          <Route
            path={routes.judgeFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Judge />
              </Authorize>
            }
          />
          <Route
            path={routes.cases}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <CasesListing />
              </Authorize>
            }
          />
          <Route
            path={routes.case}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Case />
              </Authorize>
            }
          />
          <Route
            path={routes.caseFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Case />
              </Authorize>
            }
          />
          <Route
            path={routes.lawyers}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <LawyersListing />
              </Authorize>
            }
          />
          <Route
            path={routes.lawyer}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawyer />
              </Authorize>
            }
          />
          <Route
            path={routes.lawyerFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawyer />
              </Authorize>
            }
          />
          <Route
            path={routes.lawfirms}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <LawfirmsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.lawfirm}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawfirm />
              </Authorize>
            }
          />
          <Route
            path={routes.lawfirmFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawfirm />
              </Authorize>
            }
          />
          <Route
            path={routes.schools}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <SchoolsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.university}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <University />
              </Authorize>
            }
          />
          <Route
            path={routes.universityFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <University />
              </Authorize>
            }
          />
          <Route
            path={routes.lawschool}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawschool />
              </Authorize>
            }
          />
          <Route
            path={routes.lawschoolFacet}
            element={
              <Authorize roles={[JUDICIAL_ROLE]}>
                <Lawschool />
              </Authorize>
            }
          />

          <Route
            path={routes.userApproval}
            element={
              <Authorize roles={[ADMIN_ROLE]}>
                <UserApproval />
              </Authorize>
            }
          />

          {/* <Route
            path={routes.higherEducationSalaries}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <HigherEducationSalariesListing />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationSalary}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <HigherEducationSalary />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationSalaryFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <HigherEducationSalary />
              </Authorize>
            }
          /> */}

          <Route
            path={routes.higherEducationSchools}
            element={
              <Authorize roles={[HIGHER_ED_ROLE]}>
                <HigherEducationSchoolListing />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationSchool}
            element={
              <Authorize roles={[HIGHER_ED_ROLE]}>
                <HigherEducationSchool />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationSchoolFacet}
            element={
              <Authorize roles={[HIGHER_ED_ROLE]}>
                <HigherEducationSchool />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationStudents}
            element={
              <Authorize roles={[HIGHER_ED_ROLE]}>
                <HigherEducationStudentListing />
              </Authorize>
            }
          />

          <Route
            path={routes.higherEducationEmployments}
            element={
              <Authorize roles={[HIGHER_ED_ROLE]}>
                <HigherEducationEmploymentListing />
              </Authorize>
            }
          />

          <Route
            path={routes.schoolBoards}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <SchoolBoardListing />
              </Authorize>
            }
          />

          <Route
            path={routes.schoolBoard}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <SchoolBoardVoterList />
              </Authorize>
            }
          />
          <Route
            path={routes.organizations}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <OrganizationsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.organization}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Organization />
              </Authorize>
            }
          />
          <Route
            path={routes.organizationFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Organization />
              </Authorize>
            }
          />
          <Route
            path={routes.features}
            element={
              <Authorize roles={[FEATURE_ROLE]}>
                <Features />
              </Authorize>
            }
          />
          <Route
            path={routes.teams}
            element={
              <Authorize roles={[TEAM_MANAGER]}>
                <Teams />
              </Authorize>
            }
          />

          <Route
            path={routes.teamNew}
            element={
              <Authorize roles={[TEAM_MANAGER]}>
                <Team />
              </Authorize>
            }
          />
          <Route
            path={routes.teamEdit}
            element={
              <Authorize roles={[TEAM_MANAGER]}>
                <Team />
              </Authorize>
            }
          />

          <Route
            path={routes.nursingHomes}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <NursingHomesListing />
              </Authorize>
            }
          />
          <Route
            path={routes.nursingHome}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <NursingHome />
              </Authorize>
            }
          />
          <Route
            path={routes.nursingHomeFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <NursingHome />
              </Authorize>
            }
          />
          <Route
            path={routes.subsidizedHousings}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <SubsidizedHousingListing />
              </Authorize>
            }
          />
          <Route
            path={routes.subsidizedHousing}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <SubsidizedHousing />
              </Authorize>
            }
          />
          <Route
            path={routes.subsidizedHousingFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <SubsidizedHousing />
              </Authorize>
            }
          />
          <Route
            path={routes.pensions}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <PensionsListing />
              </Authorize>
            }
          />
          <Route
            path={routes.pension}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Pension />
              </Authorize>
            }
          />
          <Route
            path={routes.pensionFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Pension />
              </Authorize>
            }
          />
          <Route
            path={routes.union}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Union />
              </Authorize>
            }
          />
          <Route
            path={routes.unionFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Union />
              </Authorize>
            }
          />
          <Route
            path={routes.unions}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <UnionsListing />
              </Authorize>
            }
          />

          <Route
            path={routes.geographies}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Geographies />
              </Authorize>
            }
          >
            <Route index element={<GeographyDashboard />} />
            <Route path="states" element={<States />} />
            <Route path="states/:id" element={<StateDetail />} />
            <Route path="zip-codes" element={<ZipCodes />} />
            <Route path="zip-codes/:stateAbbr/:id" element={<ZipCodeDetail />} />
            <Route path="counties" element={<Counties />} />
            <Route path="counties/:stateAbbr/:id" element={<CountyDetail />} />
            <Route path="cities" element={<Cities />} />
            <Route path="cities/:stateAbbr/:id" element={<CityDetail />} />
            <Route path="townships" element={<Townships />} />
            <Route path="townships/:stateAbbr/:id" element={<TownshipDetail />} />
            <Route path="neighborhoods" element={<Neighborhoods />} />
            <Route path="neighborhoods/:stateAbbr/:id" element={<NeighborhoodDetail />} />
            <Route path="precincts" element={<Precincts />} />
            <Route path="precincts/:stateAbbr/:id" element={<PrecinctDetail />} />
            <Route path="wards" element={<Wards />} />
            <Route path="wards/:stateAbbr/:id" element={<WardDetail />} />
            <Route path="us-house-districts" element={<UsHouseDistricts />} />
            <Route path="us-house-districts/:stateAbbr/:id" element={<UsHouseDistrictDetail />} />
            <Route path="state-house-districts" element={<StateHouseDistricts />} />
            <Route path="state-house-districts/:stateAbbr/:id" element={<StateHouseDistrictDetail />} />
            <Route path="state-senate-districts" element={<StateSenateDistricts />} />
            <Route path="state-senate-districts/:stateAbbr/:id" element={<StateSenateDistrictDetail />} />
            <Route path="elementary-school-districts" element={<ElementarySchoolDistricts />} />
            <Route path="elementary-school-districts/:stateAbbr/:id" element={<ElementarySchoolDistrictDetail />} />
            <Route path="middle-school-districts" element={<MiddleSchoolDistricts />} />
            <Route path="middle-school-districts/:stateAbbr/:id" element={<MiddleSchoolDistrictsDetail />} />
            <Route path="high-school-districts" element={<HighSchoolDistricts />} />
            <Route path="high-school-districts/:stateAbbr/:id" element={<HighSchoolDistrictDetail />} />
            <Route path="park-districts" element={<ParkDistricts />} />
            <Route path="park-districts/:stateAbbr/:id" element={<ParkDistrictDetail />} />
            <Route path="census-blocks" element={<CensusBlocks />} />
            <Route path="census-blocks/:stateAbbr/:id" element={<CensusBlockDetail />} />
            <Route path="census-tracts" element={<CensusTracts />} />
            <Route path="census-tracts/:stateAbbr/:id" element={<CensusTractDetail />} />
            <Route path="sub-divisions" element={<SubDivisions />} />
            <Route path="sub-divisions/:stateAbbr/:id" element={<SubDivisionDetail />} />
            <Route path="block-groups" element={<BlockGroups />} />
            <Route path="block-groups/:stateAbbr/:id" element={<BlockGroupDetail />} />
            <Route path="judicial-districts" element={<JudicialDistricts />} />
            <Route path="judicial-districts/:stateAbbr/:id" element={<JudicialDistrictDetail />} />
          </Route>

          <Route path={routes.requiresActivation} element={<RequiresActivation />} />

          <Route
            path={routes.ncoas}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <NcoaListing />
              </Authorize>
            }
          />

          <Route
            path={routes.unregisteredPeople}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <UnregisteredListing />
              </Authorize>
            }
          />

          <Route
            path={routes.voterFiles}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <VoterFilesListing />
              </Authorize>
            }
          />
          <Route
            path={routes.mobileHomes}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <MobileHomeListing />
              </Authorize>
            }
          />
          <Route
            path={routes.mobileHome}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <MobileHome />
              </Authorize>
            }
          />
          <Route
            path={routes.mobileHomeFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <MobileHome />
              </Authorize>
            }
          />
          <Route
            path={routes.dormitories}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <DormitoryListing />
              </Authorize>
            }
          />
          <Route
            path={routes.dormitory}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Dormitory />
              </Authorize>
            }
          />
          <Route
            path={routes.dormitoryFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Dormitory />
              </Authorize>
            }
          />
          <Route
            path={routes.arrest}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Arrest />
              </Authorize>
            }
          />
          <Route
            path={routes.arrestFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <Arrest />
              </Authorize>
            }
          />
          <Route
            path={routes.emailOutreaches}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <EmailOutreachListing />
              </Authorize>
            }
          />
          <Route
            path={routes.emailOutreach}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <EmailOutreach />
              </Authorize>
            }
          />
          <Route
            path={routes.emailOutreachFacet}
            element={
              <Authorize roles={[VOTER_ROLE]}>
                <EmailOutreach />
              </Authorize>
            }
          />
          <Route path={'*'} element={<NotFound />} />
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Route>
    )
  );

export const RouteMatcher = ({ versionState }: RouteMatcherProps) => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router(versionState)} />
      </Suspense>
    </>
  );
};
