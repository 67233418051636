import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../Icon';

const ToolTip: React.FC<{ message: React.ReactNode; className?: string } & React.PropsWithChildren> = ({
  message,
  children,
  className
}) => {
  return (
    <OverlayTrigger trigger={['focus', 'hover']} placement="top" overlay={<Tooltip>{message}</Tooltip>}>
      {({ ref, ...triggerHandler }) => {
        return (
          <span ref={ref} {...triggerHandler} className={className}>
            {children || <Icon style={{ color: 'var(--info)' }} icon={faQuestionCircle} />}
          </span>
        );
      }}
    </OverlayTrigger>
  );
};

export default ToolTip;
