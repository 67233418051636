import { faCheck, faThumbsUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { forwardRef, memo, useState } from 'react';
import { Button as BootstrapButton, ButtonGroup, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';
import { useAppLoading } from '../../store/app';
import Icon from '../Icon';
import ToolTip from '../ToolTip';

const Button = styled(
  forwardRef(({ className, children, round, ...rest }: ButtonProps & { round?: boolean }, ref: any) => {
    return (
      <BootstrapButton ref={ref} className={`${className} ${round ? 'btn--round' : ''}`} {...rest}>
        {children}
      </BootstrapButton>
    );
  })
)`
  &.btn {
    transition: all 0.3s;
    border-radius: var(--border-radius-sm);
    padding: 8px 12px;
    display: inline-flex;
    align-items: center;

    gap: 6px;
    & ~ .btn,
    & ~ div {
      margin-left: 8px;
    }

    &.btn-sm {
      padding: 4px 6px;
      font-size: 12px;
      border-radius: 6px;
      gap: 4px;
      line-height: inherit;
    }

    &.btn-primary {
      background-color: var(--btn-primary-bg);
      border: var(--btn-primary-border);
      &:hover {
        background-color: var(--btn-primary-bg-hover);
      }
    }
    &.btn-white {
      border: 1px solid var(--btn-white-border-color);
      box-shadow: none;
      background-color: var(--btn-white-bg);
      font-weight: 600;
      &:hover,
      &:active {
        border: 1px solid var(--btn-white-hover-bg);
        box-shadow: none;
        background-color: var(--btn-white-hover-bg);
      }
    }
    &.btn-secondary {
      background-color: var(--btn-secondary-bg);
      border: var(--btn-secondary-border);
      &:hover {
        background-color: var(--btn-secondary-bg-hover);
      }
    }
    box-shadow: var(--btn-box-shadow);

    &--round {
      border-radius: 50% !important;
      padding: 8px;
      line-height: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
      font-size: 18px;
    }
  }
`;

export const DisableWhenAppLoadingButton = forwardRef(({ disabled, children, ...rest }: ButtonProps, ref: any) => {
  const appLoading = useAppLoading();

  return (
    <Button disabled={disabled || appLoading} {...rest}>
      {children}
    </Button>
  );
});

export const ConfirmButton: React.FC<ButtonProps> = memo(({ onClick, ...rest }) => {
  const [confirming, setConfirming] = useState<boolean>(false);

  const handleConfirm = (e: any) => {
    if (!isNil(onClick)) {
      onClick!(e);
    }
  };

  const handleCancel = () => {
    setConfirming(false);
  };

  const handleClick = () => {
    setConfirming(true);
  };

  if (confirming) {
    return (
      <ButtonGroup>
        <Button size={rest.size} onClick={handleConfirm} variant={'success'} title={'Confirm'}>
          <ToolTip message={'Confirm'}>
            <Icon icon={faCheck} />
          </ToolTip>
        </Button>
        <Button size={rest.size} onClick={handleCancel} variant={'danger'} title={'Cancel'}>
          <ToolTip message={'Cancel'}>
            <Icon icon={faXmark} />
          </ToolTip>
        </Button>
      </ButtonGroup>
    );
  }

  return <Button onClick={handleClick} {...rest} />;
});

export const NextButton: React.FC<ButtonProps & { isDirty: boolean }> = ({ isDirty, ...props }) => {
  return <Button {...props}>{isDirty ? 'Save & ' : ''}Next</Button>;
};

export default Button;
