import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { memo, useCallback } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { env } from '../../env';
import { keycloakConfig } from '../../keycloak';
import { initCheckoutSession, viewBillingPortal } from '../../rest/payment';
import { setConfirmModal } from '../../store/modal';
import {
  getJWTToken,
  getParsedJWTToken,
  getRefreshToken,
  isUserActivated,
  logout,
  useUserInfo
} from '../../utils/auth';
import { useFeatureFlag } from '../../utils/features';
import { showError, showSuccess } from '../../utils/message';
import Icon from '../Icon';
import RequiresRoles from '../Security/RequiresRoles';
import { paths } from '../routes/routes';

const NavMenu: React.FC<ClassNameProps> = styled(({ className }) => {
  const subscriptionsActive = useFeatureFlag('subscriptions');
  const voterFilesActive = useFeatureFlag('voterFiles');

  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const clearCache = useCallback(() => {
    if (isNil(env.REACT_APP_CLEAR_CACHE_URL)) {
      throw new Error('REACT_APP_CLEAR_CACHE_URL is not set');
    }

    setConfirmModal({
      message: 'Are you sure you want to clear the entire application cache?',
      open: true,
      onConfirm: () => {
        const token = getJWTToken();
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${token}`);

        fetch(env.REACT_APP_CLEAR_CACHE_URL || '', { headers: headers })
          .then((r) => {
            showSuccess('Cache cleared!');
          })
          .catch((e) => {
            showError('Unable to clear cache, see console log');
            console.error(e);
          });
      }
    });
  }, []);

  const handleSelect = useCallback(
    (item: string | null) => {
      switch (item) {
        case 'sign-out':
          logout();
          break;
        case 'monitoring':
          window.location.href = `/monitoring`;
          break;
        case 'preferences':
          window.location.href = `${env.REACT_APP_AUTH_URL}/realms/${env.REACT_APP_AUTH_REALM}/account/?referrer=${keycloakConfig.clientId}`; //realms/roseland-local/account/
          break;
        case 'rollbar':
          window.location.href = `https://www.rollbar.com/projectroseland/all/items/?environments=${env.REACT_APP_HOSTNAME}`;
          break;
        case 'billing':
          viewBillingPortal();
          break;
        case 'subscribe':
          initCheckoutSession(env.REACT_APP_BASE_SUBSCRIPTION_PRICE_CODE);
          break;
        case 'clear-cache':
          clearCache();
          break;
        case 'graphiql':
          const graphiqlLocation = env.REACT_APP_GRAPHIQL_URL || '/api/graphiql';
          const refreshToken = getRefreshToken();
          window.location.href = `${graphiqlLocation}?token=${refreshToken}`;
          break;
        case 'graphiql-v2':
          const graphiqlLocationV2 = env.REACT_APP_GRAPHIQL_V2_URL || '/api/v2/graphiql';
          window.location.href = `${graphiqlLocationV2}`;
          break;
        case 'user-approval':
          navigate(paths.userApproval);
          break;
        case 'data-load':
          navigate(paths.dataLoad);
          break;
        case 'teams':
          navigate(paths.teams);
          break;
        case 'features':
          navigate(paths.features);
          break;
        case 'voter-files':
          navigate(paths.voterFiles);
          break;
        default:
          break;
      }
    },
    [clearCache, navigate]
  );

  return (
    <NavDropdown
      onSelect={handleSelect}
      className={`nav__user-menu ${className}`}
      title={
        <>
          <span className="nav__user-menu__name">
            {userInfo?.given_name} {userInfo?.family_name}
          </span>
          <span className="nav__user-menu__icon">
            <Icon icon={faUserCircle} />
          </span>
        </>
      }
      id="nav-dropdown"
    >
      {isUserActivated() && <NavDropdown.Item eventKey="preferences">Account</NavDropdown.Item>}
      {getParsedJWTToken()?.subscription_active && subscriptionsActive && (
        <NavDropdown.Item eventKey="billing">Billing</NavDropdown.Item>
      )}
      {!getParsedJWTToken()?.subscription_active && subscriptionsActive && (
        <NavDropdown.Item eventKey="subscribe">Subscribe</NavDropdown.Item>
      )}
      {isUserActivated() && <NavDropdown.Divider />}
      <RequiresRoles roles={['admin-role']}>
        <NavDropdown.Item eventKey="graphiql">API Sandbox</NavDropdown.Item>
        <NavDropdown.Item eventKey="graphiql-v2">API-V2 Sandbox</NavDropdown.Item>
        <NavDropdown.Item style={{ color: 'var(--danger)' }} eventKey="clear-cache">
          Clear Server Cache
        </NavDropdown.Item>
        <NavDropdown.Item eventKey="user-approval">User Approval Queue</NavDropdown.Item>
      
        <NavDropdown.Item eventKey="monitoring">Application Monitoring</NavDropdown.Item>
        <NavDropdown.Item eventKey="rollbar">UI Monitoring</NavDropdown.Item>
        {voterFilesActive && <NavDropdown.Item eventKey="voter-files">Voter Files</NavDropdown.Item>}
        <NavDropdown.Divider />
      </RequiresRoles>
      <RequiresRoles roles={['team-manager-role']}>
        <NavDropdown.Item eventKey="teams">My Teams</NavDropdown.Item>
      </RequiresRoles>
      <RequiresRoles roles={['data-manager-role']}>
        <NavDropdown.Item eventKey="data-load">Data Matching</NavDropdown.Item>
      </RequiresRoles>
      <RequiresRoles roles={['feature-role']}>
        <NavDropdown.Item eventKey="features">Features</NavDropdown.Item>
        <NavDropdown.Divider />
      </RequiresRoles>
      <NavDropdown.Item eventKey="sign-out">Sign Out</NavDropdown.Item>
    </NavDropdown>
  );
})`
  .nav__user-menu__icon {
    display: none;
  }
  @media (max-width: 767.98px) {
    .nav__user-menu__icon {
      display: inline-block;
    }
    .nav__user-menu__name {
      display: none;
    }
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
    width: 150%;
    left: -50%;
    box-shadow: var(--nav-menu-box-shadow);
    padding: 2px;

    a {
      padding-top: 7px;
      padding-bottom: 7px;
      color: var(--nav-menu-color);
      &:hover,
      &.active {
        color: var(--white) !important;
        background-color: var(--primary);
      }
    }
    &:before {
      border: 8px solid transparent;
      content: ' ';
      height: 0;
      left: auto;
      right: 15px;
      bottom: 100%;
      border-bottom-color: var(--nav-bg);
      pointer-events: none;
      position: absolute;
      width: 0;
    }
  }
`;

export default memo(NavMenu);
