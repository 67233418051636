import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFilterQueryCriteriaFromUrl } from '../../../utils/filter';
import { CsvProps } from '../../../components/DownloadCsv';

const SCHOOL_COLUMNS_FRAGMENT = gql`
  fragment School on ViewSchoolListing {
    id
    name
    phone
    email
    foiaEmail
    web
    facebook
    twitter
    linkedin
    description
    cityId
    city
    countyId
    county
    state
  }
`;

export const SCHOOL_LISTING = gql`
  query SchoolListing($filter: ViewSchoolListingFilter!, $offset: Int!, $orderBy: [ViewSchoolListingsOrderBy!]) {
    schools: viewSchoolListings(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...School
      }
      totalCount
    }
  }
  ${SCHOOL_COLUMNS_FRAGMENT}
`;

export const useSchoolListingQuery = (offset, sort, skip) => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const memoSort = useMemo(() => {
    return sort.length < 1 ? ['NAME_ASC'] : sort;
  }, [sort]);

  const result = useQuery(SCHOOL_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: memoSort
    },
    skip: skip,
    context: { version: 'higher-ed' }
  });

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: viewSchoolListings(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateSchoolCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: 'higher-ed' } });
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: ViewSchoolListingFilter!) {
    counties: viewSchoolListings(filter: $filter) {
      groupedAggregates(groupBy: [COUNTY_ID, COUNTY, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useSchoolCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: 'higher-ed' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: ViewSchoolListingFilter!) {
    cities: viewSchoolListings(filter: $filter) {
      groupedAggregates(groupBy: [CITY_ID, CITY, COUNTY, COUNTY_ID, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useSchoolCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: 'higher-ed' } });
};


const SCHOOLS_FOR_DOWNLOAD = gql`
  query SchoolsForDownload($filter: ViewSchoolListingFilter!, $orderBy: [ViewSchoolListingsOrderBy!]) {
    csv: viewSchoolListings(first: 250000, filter: $filter, orderBy: $orderBy) {
      nodes {
        name
        phone
        email
        foiaEmail
        web
        facebook
        twitter
        linkedin
        description
        city
        county
        state
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: SCHOOLS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      orderBy: ['NAME_ASC']
    },
    context: { version: 'higher-ed' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewSchoolListings {
      totalCount
    }
  }
`;

export const useHigherEdSchoolsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: 'higher-ed' } });
};
