import { clamp } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import Button from '../Button';
import Card from '../Card';
import { TableShadow } from '../Table';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { toNumber } from 'lodash';

export const getFormSubmitterName = (event: any) => {
  return ((event?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement)?.name;
};
export interface WizardFormProps {
  onSave: () => void;
  onNext: () => void;
  onBack: () => void;
  onStepDirty: () => void;
  goto?: (idx: number) => void;
}

export interface WizardStep {
  title: string;
  url: string;
  component: (props: WizardFormProps) => React.ReactNode;
}

interface WizardProps {
  steps: WizardStep[];
  activeIndex: number;
  afterSteps?: React.ReactNode;
}

export const WizardActions: React.FC<ClassNameProps & { children: React.ReactNode[] | React.ReactNode }> = styled(
  ({ className, children }) => {
    return <div className={className}>{children}</div>;
  }
)`
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const StepIndicator: React.FC<
  WizardProps & Omit<WizardFormProps, 'onSave' | 'onStepDirty'> & { stepSaved: boolean }
> = styled(({ className, steps, stepSaved, activeIndex, onNext, onBack, goto, afterSteps }) => {
  return (
    <div className={className}>
      <TableShadow className="wizard__steps">
        {steps.map((s: WizardStep, index: number) => {
          if (stepSaved && index === activeIndex + 1) {
            return (
              <Button variant="link" className={`wizard__steps__step `} key={s.title} onClick={() => onNext()}>
                <span>
                  {' '}
                  {index + 1}. {s.title}
                </span>
              </Button>
            );
          }

          if (index > activeIndex) {
            return (
              <span
                key={s.title}
                className={`wizard__steps__step  ${index > activeIndex ? 'wizard__steps__step--future' : ''}`}
              >
                {index + 1}. {s.title}
              </span>
            );
          }
          return (
            <Button
              className={`wizard__steps__step ${index === steps.length - 1 ? 'wizard__steps__step--last' : ''} ${
                index < activeIndex ? 'wizard__steps__step--done' : ''
              } ${index === activeIndex ? 'wizard__steps__step--active' : ''}`}
              key={s.title}
              onClick={() => {
                goto(index);
              }}
            >
              <span>
                {index + 1}. {s.title}
              </span>
            </Button>
          );
        })}
        {afterSteps}
      </TableShadow>
    </div>
  );
})`
  .wizard__steps {
    background-color: var(--white);
    line-height: 32px;
    height: 32px;
    border-radius: var(--border-radius-xs);
    display: flex;
    overflow: hidden;
    flex-direction: row;

    .wizard__steps__step {
      margin-right: 12px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 12px;
      transition: none;
      margin-left: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 0;
      text-decoration: none;
      color: var(--primary-light);
      &::after {
        content: '';

        border-top: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
        border-top-right-radius: 3px;
        display: block;
        height: 22px;
        width: 22px;
        position: absolute;
        transform: rotate(45deg);
        right: -11px;
        top: 5px;
      }

      &--done {
        margin-right: -2px;
        padding-right: 25px;
        color: var(--gray);
        background-color: var(--primary-light);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &--future {
        color: var(--gray-light);
      }
      &--active {
        background-color: var(--primary-light);
        color: var(--white);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;

        span {
          position: relative;
          z-index: 10;
        }
        &::after {
          content: '';

          background-color: inherit;
          border-top: 1px solid var(--primary-light);
          border-right: 1px solid var(--primary-light);
          display: block;
          height: 22px;
          width: 22px;
          position: absolute;
          transform: rotate(45deg);
          right: -11px;
          top: 5px;
        }
      }
      &--last {
        border-top-right-radius: var(--border-radius-sm);
        border-bottom-right-radius: var(--border-radius-sm);
        &::after {
          display: none;
        }
      }
    }
  }
`;

export const STEP_PARAM_KEY = 'stp';

const Wizard: React.FC<WizardProps> = styled(({ className, activeIndex, steps, afterSteps }) => {
  const [_activeIndex, setActiveIndex] = useState<number>(activeIndex);
  const [stepSaved, setStepSaved] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const step = searchParams.get(STEP_PARAM_KEY);
    if (!isNil(step)) {
      const stp = toNumber(step);

      if (stp > -1 && stp < steps.length) {
        setActiveIndex(stp);
        const newParams = new URLSearchParams(searchParams);
        newParams.delete(STEP_PARAM_KEY);
        setSearchParams(newParams);
      }
    }
  }, [searchParams]);

  const step = useMemo(() => {
    if (_activeIndex >= steps.length || _activeIndex < 0) {
      throw Error('Invalid step index ' + _activeIndex);
    }

    return steps[_activeIndex];
  }, [_activeIndex, steps]);

  const onSave = useCallback(() => {
    setStepSaved(true);
  }, [setStepSaved]);

  const onStepDirty = useCallback(() => {
    setStepSaved(false);
  }, [setStepSaved]);

  const onBack = useCallback(() => {
    setActiveIndex(Math.max(_activeIndex - 1, 0));
  }, [_activeIndex, setActiveIndex]);

  const onNext = useCallback(() => {
    setActiveIndex(Math.min(_activeIndex + 1, steps.length - 1));
  }, [_activeIndex, setActiveIndex, steps]);

  const goto = useCallback(
    (idx: number) => {
      setActiveIndex(clamp(idx, 0, steps.length - 1));
    },
    [setActiveIndex, steps]
  );

  return (
    <div className={className}>
      <StepIndicator
        afterSteps={afterSteps}
        steps={steps}
        stepSaved={stepSaved}
        activeIndex={_activeIndex}
        onNext={onNext}
        onBack={onBack}
        goto={goto}
      />
      <Card title={step.title}>{step.component({ onSave, onNext, onBack, goto, onStepDirty })}</Card>
    </div>
  );
})`
  .card {
    margin-top: 12px;
    border: 0;
    box-shadow: var(--header-box-shadow);
    .card-body {
      padding: 12px 8px 6px 8px;
    }
  }
`;

export default Wizard;
