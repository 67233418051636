import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBed,
  faBriefcase,
  faBuildingColumns,
  faBuildingUser,
  faCaravan,
  faEnvelopeOpenText,
  faGlobeAmericas,
  faGraduationCap,
  faHandFist,
  faHandHoldingDollar,
  faHandshake,
  faHouse,
  faHouseChimney,
  faLandmark,
  faLegal,
  faMoneyBills,
  faMoneyCheckDollar,
  faPersonBooth,
  faScaleBalanced,
  faSchoolFlag,
  faSitemap,
  faSuitcase,
  faUserGraduate,
  faUsers,
  faUserTie,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons';
import { useCampaignContributionTotalsQuery } from '../../gql/campaignContributions';
import { useCandidatesTotalsQuery } from '../../gql/candidates';
import { useCasesTotalsQuery } from '../../gql/cases';
import { useCourtsTotalsQuery } from '../../gql/courts';
import { useDormitoryTotalsQuery } from '../../gql/dormitories';
import { useElectionResultsTotalsQuery } from '../../gql/electionResults';
import { useEmailOutreachesTotalsQuery } from '../../gql/emailOutreaches';
import { useHigherEdSalariesTotalsQuery } from '../../gql/higheredsalaries';
import { useJudgesTotalsQuery } from '../../gql/judges';
import { useLawFirmsTotalsQuery } from '../../gql/lawfirms';
import { useLawyersTotalsQuery } from '../../gql/lawyers';
import { useMobileHomeTotalsQuery } from '../../gql/mobileHomes';
import { useNcoaTotalsQuery } from '../../gql/ncoa';
import { useNursingHomeTotalsQuery } from '../../gql/nursingHomes';
import { useOrganizationsTotalsQuery } from '../../gql/organizations';
import { usePensionsTotalsQuery } from '../../gql/pensions';
import { usePeopleTotalsQuery } from '../../gql/people';
import { usePPPTotalsQuery } from '../../gql/pppLoans';
import { usePropertiesTotalsQuery } from '../../gql/properties';
import { useSchoolBoardTotalsQuery } from '../../gql/schoolBoards';
import { useSchoolsTotalsQuery } from '../../gql/schools';
import { useSubsidizedHousingTotalsQuery } from '../../gql/subsidizedHousings';
import { useUnionsTotalsQuery } from '../../gql/unions';
import { routes } from '../routes/routes';
import { useHigherEdSchoolsTotalsQuery } from '../../gql/higherEd/schools';
import { useHigherEdStudentsTotalsQuery } from '../../gql/higherEd/students';
import { useHigherEdEmploymentsTotalsQuery } from '../../gql/higherEd/employments';

export interface SubMenu {
  submenu: true;
  title: string;
  roles: string[];
  icon: IconProp;
  navItems: (NavItem | SubMenu)[];
  type: string;
  feature?: string;
}

export interface NavItem {
  submenu?: false;
  roles: string[];
  filterType: string;
  title: string;
  route: string;
  icon: IconProp;
  subNavType?: string;
  queryHook?: any;
}

const NavigationConfig: (NavItem | SubMenu)[] = [
  {
    roles: ['voter-role'],
    filterType: 'people',
    title: 'People',
    route: routes.people,
    icon: faUsers,
    queryHook: usePeopleTotalsQuery
  },
  {
    roles: ['voter-role'],
    filterType: 'properties',
    title: 'Properties',
    route: routes.properties,
    icon: faHouse,
    queryHook: usePropertiesTotalsQuery
  },
  {
    roles: ['voter-role'],
    filterType: 'organizations',
    title: 'Organizations',
    route: routes.organizations,
    icon: faSitemap,
    queryHook: useOrganizationsTotalsQuery
  },
  {
    roles: ['voter-role'],
    filterType: 'geographies',
    title: 'Geographies',
    route: routes.geographies,
    icon: faGlobeAmericas,
    queryHook: useSchoolBoardTotalsQuery
  },
  // {
  //   roles: ['voter-role'],
  //   filterType: 'unregistered-people',
  //   title: 'Unregistered People',
  //   route: routes.unregisteredPeople,
  //   icon: faUsersViewfinder,
  //   queryHook: useUnregisteredTotalsQuery
  // },
  {
    roles: ['voter-role'],
    filterType: 'ncoa',
    title: 'NCOA Records',
    route: routes.ncoas,
    icon: faSuitcase,
    queryHook: useNcoaTotalsQuery
  },
  {
    roles: ['voter-role'],
    filterType: 'email-outreach',
    title: 'FOIA Requests',
    route: routes.emailOutreaches,
    icon: faEnvelopeOpenText,
    queryHook: useEmailOutreachesTotalsQuery
  },
  {
    submenu: true,
    roles: ['voter-role'],
    title: 'Campaigns & Elections',
    icon: faPersonBooth,
    type: 'campaigns-elections',
    navItems: [
      {
        roles: ['voter-role'],
        filterType: 'election-results',
        subNavType: 'campaigns-elections',
        title: 'Election Results',
        route: `${routes.electionResults}?type-eq=GE`,
        icon: faPersonBooth,
        queryHook: useElectionResultsTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'cmpgn-cont',
        subNavType: 'campaigns-elections',
        title: 'Campaign Contributions',
        route: routes.campaignContributions,
        icon: faMoneyBills,
        queryHook: useCampaignContributionTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'candidates',
        subNavType: 'campaigns-elections',
        title: 'Candidates for Office',
        route: routes.candidates,
        icon: faHandshake,
        queryHook: useCandidatesTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['judicial-role'],
    title: 'Law & Courts',
    icon: faLegal,
    type: 'law-courts',
    navItems: [
      {
        roles: ['judicial-role'],
        filterType: 'cases',
        subNavType: 'law-courts',
        title: 'Cases',
        route: routes.cases,
        icon: faLandmark,
        queryHook: useCasesTotalsQuery
      },
      {
        roles: ['judicial-role'],
        filterType: 'courts',
        subNavType: 'law-courts',
        title: 'Courts',
        route: routes.courts,
        icon: faScaleBalanced,
        queryHook: useCourtsTotalsQuery
      },
      {
        roles: ['judicial-role'],
        filterType: 'judges',
        subNavType: 'law-courts',
        title: 'Judges',
        route: routes.judges,
        icon: faLegal,
        queryHook: useJudgesTotalsQuery
      },
      {
        roles: ['judicial-role'],
        filterType: 'lawyers',
        subNavType: 'law-courts',
        title: 'Lawyers',
        route: routes.lawyers,
        icon: faUserTie,
        queryHook: useLawyersTotalsQuery
      },
      {
        roles: ['judicial-role'],
        filterType: 'lawfirms',
        subNavType: 'law-courts',
        title: 'Law Firms',
        route: routes.lawfirms,
        icon: faBriefcase,
        queryHook: useLawFirmsTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['higher-ed-role'],
    title: 'Higher Education',
    icon: faGraduationCap,
    type: 'higher-ed',
    navItems: [
      // {
      //   roles: ['judicial-role'],
      //   filterType: 'schools',
      //   subNavType: 'higher-ed',
      //   title: 'Schools',
      //   route: routes.schools,
      //   icon: faGraduationCap,
      //   queryHook: useSchoolsTotalsQuery
      // },
      // {
      //   roles: ['voter-role'],
      //   filterType: 'higher-ed-salaries',
      //   subNavType: 'higher-ed',
      //   title: 'Higher Education Salaries',
      //   route: routes.higherEducationSalaries,
      //   icon: faUserGraduate,
      //   queryHook: useHigherEdSalariesTotalsQuery
      // },
      {
        roles: ['higher-ed-role'],
        filterType: 'higher-ed-schools',
        subNavType: 'higher-ed',
        title: 'Universities/Colleges',
        route: routes.higherEducationSchools,
        icon: faBuildingColumns,
        queryHook: useHigherEdSchoolsTotalsQuery
      },
      {
        roles: ['higher-ed-role'],
        filterType: 'higher-ed-students',
        subNavType: 'higher-ed',
        title: 'Students',
        route: routes.higherEducationStudents,
        icon: faUserGraduate,
        queryHook: useHigherEdStudentsTotalsQuery
      },
      {
        roles: ['higher-ed-role'],
        filterType: 'higher-ed-employments',
        subNavType: 'higher-ed',
        title: 'Employments',
        route: routes.higherEducationEmployments,
        icon: faUserTie,
        queryHook: useHigherEdEmploymentsTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['voter-role'],
    title: 'K-12 Schools',
    icon: faSchoolFlag,
    type: 'k-12',
    navItems: [
      {
        roles: ['voter-role'],
        filterType: 'school-boards',
        subNavType: 'k-12',
        title: 'School Boards',
        route: routes.schoolBoards,
        icon: faSchoolFlag,
        queryHook: useSchoolBoardTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['voter-role'],
    title: 'Government Subsidies',
    icon: faHandHoldingDollar,
    type: 'govt-subsidies',
    navItems: [
      {
        roles: ['voter-role'],
        filterType: 'ppp',
        subNavType: 'govt-subsidies',
        title: 'PPP Loans',
        route: routes.pppLoans,
        icon: faHandHoldingDollar,
        queryHook: usePPPTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['voter-role'],
    title: 'Housing',
    icon: faHouseChimney,
    type: 'housing',
    navItems: [
      {
        roles: ['voter-role'],
        filterType: 'nursing-homes',
        subNavType: 'housing',
        title: 'Nursing Homes',
        route: routes.nursingHomes,
        icon: faWheelchair,
        queryHook: useNursingHomeTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'subsidized-housing',
        subNavType: 'housing',
        title: 'Subsidized Housing',
        route: routes.subsidizedHousings,
        icon: faBuildingUser,
        queryHook: useSubsidizedHousingTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'mobile-home',
        subNavType: 'housing',
        title: 'Mobile Home Parks',
        route: routes.mobileHomes,
        icon: faCaravan,
        queryHook: useMobileHomeTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'dormitory',
        subNavType: 'housing',
        title: 'Dormitories',
        route: routes.dormitories,
        icon: faBed,
        queryHook: useDormitoryTotalsQuery
      }
    ]
  },
  {
    submenu: true,
    roles: ['voter-role'],
    title: 'Employment',
    icon: faMoneyCheckDollar,
    type: 'employment',
    navItems: [
      {
        roles: ['voter-role'],
        filterType: 'pensions',
        subNavType: 'employment',
        title: 'Pensions',
        route: routes.pensions,
        icon: faMoneyCheckDollar,
        queryHook: usePensionsTotalsQuery
      },
      {
        roles: ['voter-role'],
        filterType: 'unions',
        subNavType: 'employment',
        title: 'Union Membership',
        route: routes.unions,
        icon: faHandFist,
        queryHook: useUnionsTotalsQuery
      }
    ]
  }
];

export default NavigationConfig;
