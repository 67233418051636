import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../../components/DownloadCsv';
import { useFilterQueryCriteriaFromUrl } from '../../../utils/filter';

const TYPE_PARAM_OVERRIDE = {'type-eq': ['education']};

const STUDENT_COLUMNS_FRAGMENT = gql`
  fragment Student on ViewStudentListing {
    id
    personId
    firstName
    middleName
    lastName
    suffix
    phone
    birthdate
    twitter
    facebook
    linkedin
    instagram    
    fromYear
    toYear
    level
    degree
    organizationId
    organization
    city
    county
    state
  }
`;

export const STUDENT_LISTING = gql`
  query StudentListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewStudentListingsOrderBy!]) {
    students: viewStudentListings(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Student
      }
      totalCount
    }
  }
  ${STUDENT_COLUMNS_FRAGMENT}
`;

export const useStudentListingQuery = (offset, sort) => {

  const [searchParams] = useSearchParams();

  let queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', TYPE_PARAM_OVERRIDE);

  sort = sort.length < 1 ? ['LAST_NAME_ASC', 'FIRST_NAME_ASC'] : sort;

  const result = useQuery(STUDENT_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: 'higher-ed' }
  });

  return result;
};

const STATES_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewStudentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStateStudentCountQuery = () => {
  const filter: any = {
    query: {
      bool: {
        filter: {
          term: {
            type: 'education'
          }
        }
      }
    },
    aggs: {
      results: {
        terms: {
          field: 'state',
          size: 59
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: 'higher-ed' } };

  return useLazyQuery(STATES_QUERY, vars);
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewStudentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStudentCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: 'higher-ed' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewStudentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStudentCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: 'higher-ed' } });
};

const ORGANIZATIONS_QUERY = gql`
  query OrganizationsForState($filter: JSONObject!) {
    organizations: viewStudentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStudentOrganizations = () => {
  return useLazyQuery(ORGANIZATIONS_QUERY, { context: { version: 'higher-ed' } });
};

const STUDENTS_FOR_DOWNLOAD = gql`
  query StudentsForDownload(
    $first: Int!
    $filter: JSONObject!
    $offset: Int!
    $orderBy: [ViewStudentListingsOrderBy!]
  ) {
    csv: viewStudentListings(first: 250000, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        firstName
        middleName
        lastName
        suffix
        phone
        birthdate
        twitter
        facebook
        linkedin
        instagram    
        fromYear
        toYear
        level
        degree
        organization
        city
        county
        state
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', TYPE_PARAM_OVERRIDE);

  const csvProps: CsvProps = {
    query: STUDENTS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC, FIRST_NAME_ASC']
    },
    context: { version: 'higher-ed' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery($filter: JSONObject!) {
    dashboardTotals: viewStudentListings(elasticSearch: $filter) {
      totalCount
    }
  }
`;

export const useHigherEdStudentsTotalsQuery = () => {
  const result = useQuery(TOTALS_QUERY, {
    variables: {
      filter: {
        query: {
          bool: {
            filter: {
              term: {
                type: 'education'
              }
            }
          }
        }
      }
    },
    context: { version: 'higher-ed' }
  });


  return result;
};
