import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../../components/DownloadCsv';
import { useFilterQueryCriteriaFromUrl } from '../../../utils/filter';

const TYPE_PARAM_OVERRIDE = {'type-eq': ['employment']};

const EMPLOYMENT_COLUMNS_FRAGMENT = gql`
  fragment Employment on ViewEmploymentListing {
    id
    personId
    firstName
    middleName
    lastName
    suffix
    phone
    birthdate
    twitter
    facebook
    linkedin
    instagram    
    year
    jobTitle
    compensationType
    totalCompensation
    organizationId
    organization
    city
    county
    state
  }
`;

export const EMPLOYMENT_LISTING = gql`
  query EmploymentListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewEmploymentListingsOrderBy!]) {
    employments: viewEmploymentListings(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Employment
      }
      totalCount
    }
  }
  ${EMPLOYMENT_COLUMNS_FRAGMENT}
`;

export const useEmploymentListingQuery = (offset, sort) => {

  const [searchParams] = useSearchParams();

  let queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', TYPE_PARAM_OVERRIDE);

  sort = sort.length < 1 ? ['LAST_NAME_ASC', 'FIRST_NAME_ASC'] : sort;

  const result = useQuery(EMPLOYMENT_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: 'higher-ed' }
  });

  return result;
};

const STATES_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewEmploymentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStateEmploymentCountQuery = () => {
  const filter: any = {
    query: {
      bool: {
        filter: {
          term: {
            type: 'employment'
          }
        }
      }
    },
    aggs: {
      results: {
        terms: {
          field: 'state',
          size: 59
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: 'higher-ed' } };

  return useLazyQuery(STATES_QUERY, vars);
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewEmploymentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useEmploymentCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: 'higher-ed' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewEmploymentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useEmploymentCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: 'higher-ed' } });
};

const ORGANIZATIONS_QUERY = gql`
  query OrganizationsForState($filter: JSONObject!) {
    organizations: viewEmploymentListings(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useEmploymentOrganizations = () => {
  return useLazyQuery(ORGANIZATIONS_QUERY, { context: { version: 'higher-ed' } });
};

const EMPLOYMENTS_FOR_DOWNLOAD = gql`
  query EmploymentsForDownload(
    $first: Int!
    $filter: JSONObject!
    $offset: Int!
    $orderBy: [ViewEmploymentListingsOrderBy!]
  ) {
    csv: viewEmploymentListings(first: 250000, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        firstName
        middleName
        lastName
        suffix
        phone
        year
        birthdate
        twitter
        facebook
        linkedin
        instagram    
        jobTitle
        compensationType
        totalCompensation
        organization
        city
        county
        state
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', TYPE_PARAM_OVERRIDE);

  const csvProps: CsvProps = {
    query: EMPLOYMENTS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC, FIRST_NAME_ASC']
    },
    context: { version: 'higher-ed' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery($filter: JSONObject!) {
    dashboardTotals: viewEmploymentListings(elasticSearch: $filter) {
      totalCount
    }
  }
`;

export const useHigherEdEmploymentsTotalsQuery = () => {
  const result = useQuery(TOTALS_QUERY, {
    variables: {
      filter: {
        query: {
          bool: {
            filter: {
              term: {
                type: 'employment'
              }
            }
          }
        }
      }
    },
    context: { version: 'higher-ed' }
  });


  return result;
};
